import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import Seo from "../components/seo"

const shortcodes = { Link }

const CaseStudyTemplate = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <article
        className="case-study pt-32 mx-auto w-4/5"
        itemScope
        itemType="http://schema.org/Article"
      >
        <MDXProvider components={shortcodes}>
          <MDXRenderer frontmatter={post.frontmatter} >{post.body}</MDXRenderer>
        </MDXProvider>
        <hr />
        <footer>
        </footer>
      </article>
      {/* <nav className="case-study-nav mx-auto w-4/5">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

export default CaseStudyTemplate

export const pageQuery = graphql`
    query CaseStudyBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        site {
            siteMetadata {
                title
            }
        }
        mdx(id: { eq: $id }) {
            id
            body
            excerpt(pruneLength: 160)
            frontmatter {
                title
                contentType
                featuredImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                }
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
        previous: mdx(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: mdx(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`
